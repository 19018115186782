import { useEffect, useState } from "react";
import "./App.css";
import {
  loadFaceLandmarkModel,
  loadFaceRecognitionModel,
  loadSsdMobilenetv1Model,
  FaceMatcher,
  LabeledFaceDescriptors,
  detectSingleFace,
  detectAllFaces,
  draw,
  matchDimensions,
  resizeResults,
} from "face-api.js";

const MODEL_URL = "/models";
const FACE_MATCHER_THRESHOLD = 0.5;

const loadModels = async () => {
  await loadSsdMobilenetv1Model(MODEL_URL);
  await loadFaceLandmarkModel(MODEL_URL);
  await loadFaceRecognitionModel(MODEL_URL);
};

const processImagesForRecognition = async (imageElements) => {
  let labeledFaceDescriptors = [];

  try {
    labeledFaceDescriptors = await Promise.all(
      imageElements?.map(async (imageEle) => {
        if (imageEle) {
          const label = imageEle?.alt.split(" ")[0];
          const faceDescription = await detectSingleFace(imageEle)
            .withFaceLandmarks()
            .withFaceDescriptor();
          if (!faceDescription) {
            throw new Error(`no faces detected for ${label}`);
          }

          const faceDescriptors = [faceDescription.descriptor];
          console.log(faceDescriptors);
          return new LabeledFaceDescriptors(label, faceDescriptors);
        }
      })
    );
  } catch (error) {
    console.error(error);
    return null;
  }
  console.log(labeledFaceDescriptors);

  return new FaceMatcher(labeledFaceDescriptors, FACE_MATCHER_THRESHOLD);
};

const recognizeFace = async (queryImageElement, faceMatcher) => {
  const queryCanvasElement = document.querySelector("#recognition-result");
  const ctx = queryCanvasElement.getContext("2d");

  const resultsQuery = await detectAllFaces(queryImageElement)
    .withFaceLandmarks()
    .withFaceDescriptors();

  await matchDimensions(queryCanvasElement, queryImageElement);
  ctx.drawImage(queryImageElement, 0, 0);

  const results = await resizeResults(resultsQuery, {
    width: queryImageElement.width,
    height: queryImageElement.height,
  });

  const bestMatches = results.map((res) => {
    return faceMatcher.findBestMatch(res.descriptor);
  });

  const queryDrawBoxes = results.map((res, index) => {
    const bestMatch = bestMatches[index];
    return new draw.DrawBox(res.detection.box, {
      label: bestMatch.toString(),
    });
  });

  queryDrawBoxes.forEach((drawBox) => drawBox.draw(queryCanvasElement));

  return bestMatches;
};

function createLoadedImage(file) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const image = new Image();
      image.src = fileReader.result;
      image.alt = "reference";
      image.onload = () => {
        resolve(image);
      };
    };
    fileReader.readAsDataURL(file);
  });
}

function App() {
  const [faceMatcher, setFaceMatcher] = useState(null);
  const [bestMatches, setBestMatches] = useState([]);
  const [idCardError, setIdCardError] = useState("");
  const [isProcessingIdCardPhoto, setIsProcessingIdCardPhoto] = useState(false);

  const doesMatchReference = bestMatches.some(
    (bestMatch) => bestMatch.label === "reference"
  );

  useEffect(() => {
    loadModels();
  }, []);

  async function handleDbImageSelection(event) {
    setFaceMatcher(null);
    const fileList = event.target.files;

    if (!fileList.length) {
      return;
    }

    const files = [];

    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList.item(i));
    }

    const imagePromises = files.map((file) => createLoadedImage(file));

    setIsProcessingIdCardPhoto(true);

    const images = await Promise.all(imagePromises);

    const faceMatcher = await processImagesForRecognition(images);
    if (faceMatcher) {
      setFaceMatcher(faceMatcher);
    } else {
      setIdCardError("No face detected on photo!");
    }

    setIsProcessingIdCardPhoto(false);
  }

  async function handleQueryImageSelection(event) {
    const image = await createLoadedImage(event.target.files[0]);
    const bestMatches = await recognizeFace(image, faceMatcher);
    setBestMatches(bestMatches);
  }

  return (
    <div className="App">
      <div className="section">
        <span className="form-label">Load identity card photo</span>
        <input type="file" onChange={handleDbImageSelection} />
        {isProcessingIdCardPhoto && (
          <div>
            Processing photo <i className="c-inline-spinner"></i>
          </div>
        )}
        <div className="error">{idCardError}</div>
      </div>

      <div className="section">
        <span className="form-label">Load photo of yourself</span>
        <input
          type="file"
          disabled={!faceMatcher}
          onChange={handleQueryImageSelection}
        />
      </div>

      <div className="section">
        <span>Result: </span>
        {bestMatches.length ? (
          <b>{doesMatchReference ? "matches" : "does not match"}</b>
        ) : (
          ""
        )}
      </div>

      <canvas id="recognition-result"></canvas>
    </div>
  );
}

export default App;
